import logo from './logo.svg';
import './App.css';
import { Amplify, API } from 'aws-amplify'
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css'
import config from './aws-exports'


import { React, useEffect, useState } from 'react'

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';


import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ListItemText from '@mui/material/ListItemText';

import Pagination from '@mui/material/Pagination';
import Grid from '@mui/material/Grid';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css'

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';


import { MapContainer, TileLayer, Marker, Popup, } from 'react-leaflet'

import { useMap } from 'react-leaflet/hooks'

import { CSVLink } from "react-csv";

const myAPI = "apid30cb597"
const path = '/items'

const maxItemsPerPage = 5

Amplify.configure(config)

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [14, 40],
  popupAnchor: [-1, -40],
});

L.Marker.prototype.options.icon = DefaultIcon;

function App({ signOut, user }) {


  const [map, setMap] = useState(null);

  L.Icon.Default.imagePath = 'leaflet_images/';

  const [csvData, setCsvData] = useState([]);
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);

  const [centerChanged, setCenterChanged] = useState(false);
  const [centerPosition, setCenterPosition] = useState([63.443043176616015, 10.429135718747977]);
  // List pagination. 
  const [currentPage, setCurrentPage] = useState(1)


  //Function to fetch from our backend and update customers array
  function loadItems(key, isCenterChanged) {
    API.get(myAPI, path + "/" + key)
      .then(response => {
        // console.log(response)
        // Mapping value to dictionary object.
        response.map(element => {
          element['value'] = jsonParse(element['value'])
        });


        var counter = 0;
        var avgCenter = [0, 0]
        //Looking for the center of the events. 


        response.forEach(element => {
          if (element['value'] !== undefined && element['value']['geo'] !== undefined) {

            var total = counter + 1
            if (counter > 0) {
              avgCenter[0] = (avgCenter[0] * counter / total) + (element['value']['geo'][0] / total)
              avgCenter[1] = (avgCenter[1] * counter / total) + (element['value']['geo'][1] / total)
            } else {
              avgCenter[0] += element['value']['geo'][0]
              avgCenter[1] += element['value']['geo'][1]
            }
            counter += 1;
          }
        });


        if (counter >= 0 && !isCenterChanged) {
          // console.log(isCenterChanged);
          // console.log(`Changing center to : ${avgCenter}`);
          setCenterChanged(true);
          if (map) {
            map.flyTo(avgCenter, 13)
          }

          setCenterPosition(avgCenter);
        }


        setCsvData(response.map(x => {
          var item = { "ts": x['ts'], "sn": x.value['product_serial_number'], "score": parseInt(x.value['score'], 10), "trigger": x.value['manual'] ? 'Manual' : 'Auto' };

          if (x['value'] !== undefined && x['value']['geo'] !== undefined) {
            item['location'] = true
            item['lat'] = x['value']['geo'][0];
            item['lng'] = x['value']['geo'][1];
          } else {
            item['location'] = false;
            item['lat'] = null;
            item['lng'] = null;
          }
          return item;
        }));

        setItems(response)
      })
      .catch(error => {
        console.log(error)
      })
  }

  function ChangeMap({ test, zoom }) {
    const map = useMap();
    map.setView(test, zoom);
    return null;
  }

  function jsonParse(str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return {};
    }
  }


  function handlePageChange(event, value) {
    // console.log(event);
    // console.log(value);
    setCurrentPage(value);
  }

  const handleListClick = (value) => () => {
    // console.log(`ev:${value}`);

    var item = items[value];
    if (item['value'] !== undefined && item['value']['geo'] !== undefined) {
      // console.log(item);
      if (!map) {
        return
      }
      map.flyTo(item['value']['geo'], 13)
    }

    // console.log(JSON.stringify(item));

    if (item['markerRef'] !== undefined) {
      item['markerRef'].openPopup();
    }


  };


  // Setting timer for periodic update. 
  useEffect(() => {
    loadItems('impact', centerChanged)
    const interval = setInterval(() => loadItems('impact', centerChanged), 2000);
    return () => {
      clearInterval(interval);
    };
  }, [centerChanged, map]);

  function formaterEvtTime(item) {
    return <>{new Date(item.ts * 1000).toLocaleString()}</>;
  }

  function formaterEvtDescription(item) {
    return ( <>
      <b>Devices:</b> {item.value['device_uuid']} <br />
      <b>S/N:</b> {item.value['product_serial_number']} <br />
      <b>Score:</b> {item.value['score']} <br />
      <b>Trigger:</b> {item.value['manual'] ? 'Manual' : 'Auto'} <br />
      </>
    )
  }

  return (
    <div className="App">
      <header className="App-header">

        <Grid container spacing={2}>
          <Grid item xs={4} md={2}>
            <Button variant="outlined" onClick={signOut}>Sign Out</Button>
          </Grid>
          <Grid item xs={4} md={2}>
            <CSVLink data={csvData}
              filename={"report.csv"}>Download CSV</CSVLink>
          </Grid>

        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <h2>Last impact events</h2>


            <Pagination
              count={items.length % maxItemsPerPage == 0 ? items.length / maxItemsPerPage : parseInt(items.length / maxItemsPerPage, 10) + 1}
              page={currentPage}
              color="primary"
              variant="outlined"
              onChange={handlePageChange}
            />

            <List>
              {items.map((item, i) => {
                var itemPage = parseInt(i / maxItemsPerPage, 10) + 1
                if (itemPage == currentPage) {

                  var locationIcon = ""
                  if (item.value.geo !== undefined) {
                    locationIcon = <LocationOnIcon />
                  }

                  return (
                    <ListItem disablePadding divider key={i}>
                      <ListItemButton role={undefined} onClick={handleListClick(i)} dense>
                        <ListItemIcon>
                          {locationIcon}
                        </ListItemIcon>
                        <ListItemText primary={formaterEvtTime(item)} secondary={formaterEvtDescription(item)} />
                      </ListItemButton>
                    </ListItem>
                  )
                }
              }
              )}
            </List>
          </Grid>
          <Grid item xs={12} md={8}>
            <div id="map">
              <MapContainer
                center={centerPosition} zoom={13} scrollWheelZoom={true}
                ref={setMap}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                {items.map((item, i) => {
                  if (item.value['geo'] !== undefined) {
                    return (
                      <Marker ref={(ref) => { item.markerRef = ref }} key={`marker-${i}`} position={item.value['geo']}>
                        <Popup>
                          <b>{formaterEvtTime(item)}</b>


                          <span>{formaterEvtDescription(item)}</span>
                        </Popup>
                      </Marker>
                    )
                  }
                }
                )}

              </MapContainer>
            </div>
          </Grid>
        </Grid>



      </header>
    </div>
  );
}

export default withAuthenticator(App, { hideSignUp: true });
