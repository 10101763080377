/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-north-1",
    "aws_cognito_identity_pool_id": "eu-north-1:8c04b4cb-39dd-4faa-9395-785bdb178388",
    "aws_cognito_region": "eu-north-1",
    "aws_user_pools_id": "eu-north-1_E3ojReJgo",
    "aws_user_pools_web_client_id": "5mamd1qf8hj5g2074aldloa88d",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "eu-north-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "dynamo5160bdf5-dev",
            "region": "eu-north-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "apid30cb597",
            "endpoint": "https://jp2gv26mei.execute-api.eu-north-1.amazonaws.com/dev",
            "region": "eu-north-1"
        }
    ]
};


export default awsmobile;
